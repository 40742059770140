
// IMPORT-----------------------------------------------------------
import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, setDirectAccessibleData } from "../../../config";

// IMPORT END-------------------------------------------------------
@Component
export default class ForgotPasswordComponent extends Vue {
  public forgotPassword: any = {
    token: null,
  };

  public error = {};
  public msg = null;
  public passwordFieldType = 'password'
  public conPasswordFieldType = 'password'
  /**
   * Submitting the data to the server for changing the passsword
   */
  public async validateAndSubmit() {
    try {
      if(this.forgotPassword.confirmPswrd != this.forgotPassword.newPswrd)
        return;
      const apiUrl = this.$route.query.id
        ? "authentication/verifyTokenAndChangePassword"
        : "authentication/sendChangePasswordLink";
      this.forgotPassword.token = this.$route.query.id
        ? this.$route.query.id
        : null;

      const response = await Axios.post(
        BASE_API_URL + apiUrl,
        this.forgotPassword
      );
      
      this.$refs.observer["reset"]();
      if (response.status === 202) {
        this.msg = response.data.message;
      } if (response.status === 203) {
        this.$snotify.error(response.data.message);
      } else {
        if (response.data.type !== "Success") {
          this.error = response.data;
        } else if (this.$route.query.id) {
          this.$router.replace({ path: "/" });
          this.$snotify.success("Password Changed.Please Login");
        } else {
          this.forgotPassword = {};
          this.$snotify.success("Mail sent to this email");
          this.$router.replace({ path: "/" });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  public trimEmail() {
    this.forgotPassword.email = this.forgotPassword.email.trim();
  }

   public switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    }
    public switchConPassVisibility() {
      this.conPasswordFieldType = this.conPasswordFieldType === 'password' ? 'text' : 'password'
    }

    

  mounted() {
    document.title = this.$route.meta.title;
  }
}
